import z from 'zod'
import authApiRequest from '@/api-data/auth'

export const registerSendOtpBody = z.object({
  full_name: z.string().trim().min(4).max(100),
  phone: z.string().trim().min(10).max(10),
})

export type RegisterSendOtpType = z.infer<typeof registerSendOtpBody>

export const getSchemaRegister = (t: any) => {
  return z.object({
    full_name: z
      .string()
      .trim()
      .min(4, t('ho_ten') + t('min_length', { length: 4 }))
      .max(100, t('ho_ten') + t('max_length', { length: 100 }))
      .regex(
        /^[\p{L}]+(?:[\s\-][\p{L}]+)*$/u,
        t('ho_ten') + t('only_contain_letter')
      ),
    phone: z
      .string()
      .trim()
      .min(10, t('sdt') + t('equal_length', { length: 10 }))
      .max(10, t('sdt') + t('equal_length', { length: 10 }))
      .regex(
        /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/,
        t('sdt') + t('val_phone')
      ),
  })
}

export const getSchemaLogin = (t: any) => {
  return z.object({
    phone: z
      .string()
      .trim()
      .min(10, t('sdt') + t('equal_length', { length: 10 }))
      .max(10, t('sdt') + t('equal_length', { length: 10 }))
      .regex(
        /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/,
        t('sdt') + t('val_phone')
      ),
    password: z
      .string()
      .trim()
      .min(8, t('mat_khau') + t('min_length', { length: 8 }))
      .max(32, t('mat_khau') + t('max_length', { length: 32 }))
      .regex(/[a-z]/, t('mat_khau') + t('contain_lowercase_character'))
      .regex(/[A-Z]/, t('mat_khau') + t('contain_uppercase_character'))
      .regex(/[0-9]/, t('mat_khau') + t('contain_number_character'))
      .regex(/[\W_]/, t('mat_khau') + t('contain_special_character')),
  })
}

export const getSchemaConfirmPassword = (t: any) => {
  return z.object({
    password: z
      .string()
      .trim()
      .min(8, t('mat_khau') + t('min_length', { length: 8 }))
      .max(32, t('mat_khau') + t('max_length', { length: 32 }))
      .regex(/[a-z]/, t('mat_khau') + t('contain_lowercase_character'))
      .regex(/[A-Z]/, t('mat_khau') + t('contain_uppercase_character'))
      .regex(/[0-9]/, t('mat_khau') + t('contain_number_character'))
      .regex(/[\W_]/, t('mat_khau') + t('contain_special_character'))
      .regex(/^\S+$/, t('mat_khau') + t('not_contain_space')),
    confirm_password: z
      .string()
      .trim()
      .min(8, t('nhap_lai_mat_khau') + t('min_length', { length: 8 }))
      .max(32, t('nhap_lai_mat_khau') + t('max_length', { length: 32 }))
      .regex(/[a-z]/, t('nhap_lai_mat_khau') + t('contain_lowercase_character'))
      .regex(/[A-Z]/, t('nhap_lai_mat_khau') + t('contain_uppercase_character'))
      .regex(/[0-9]/, t('nhap_lai_mat_khau') + t('contain_number_character'))
      .regex(/[\W_]/, t('nhap_lai_mat_khau') + t('contain_special_character'))
      .regex(/^\S+$/, t('nhap_lai_mat_khau') + t('not_contain_space')),
  })
}

export const registerPassword = z
  .object({
    password: z
      .string()
      .trim()
      .min(8, 'Mật khẩu mới phải có ít nhất 8 ký tự')
      .max(32, 'Mật khẩu mới không được vượt quá 32 ký tự')
      .regex(/[a-z]/, 'Mật khẩu mới phải chứa ít nhất một chữ cái thường')
      .regex(/[A-Z]/, 'Mật khẩu mới phải chứa ít nhất một chữ cái hoa')
      .regex(/[0-9]/, 'Mật khẩu mới phải chứa ít nhất một số')
      .regex(/[\W_]/, 'Mật khẩu mới phải chứa ít nhất một ký tự đặc biệt'),
    confirm_password: z
      .string()
      .trim()
      .min(8, 'Mật khẩu mới phải có ít nhất 8 ký tự')
      .max(32, 'Mật khẩu mới không được vượt quá 32 ký tự')
      .regex(/[a-z]/, 'Mật khẩu mới phải chứa ít nhất một chữ cái thường')
      .regex(/[A-Z]/, 'Mật khẩu mới phải chứa ít nhất một chữ cái hoa')
      .regex(/[0-9]/, 'Mật khẩu mới phải chứa ít nhất một số')
      .regex(/[\W_]/, 'Mật khẩu mới phải chứa ít nhất một ký tự đặc biệt'),
  })
  .refine((data) => data.password === data.confirm_password, {
    message: 'Mật khẩu mới không khớp',
    path: ['confirm_password'],
  })

export type RegisterPasswordType = z.infer<typeof registerPassword>

export const loginBody = z.object({
  phone: z.string().trim().min(10).max(10),
  password: z
    .string()
    .trim()
    .min(8, 'Mật khẩu mới phải có ít nhất 8 ký tự')
    .max(32, 'Mật khẩu mới không được vượt quá 32 ký tự')
    .regex(/[a-z]/, 'Mật khẩu mới phải chứa ít nhất một chữ cái thường')
    .regex(/[A-Z]/, 'Mật khẩu mới phải chứa ít nhất một chữ cái hoa')
    .regex(/[0-9]/, 'Mật khẩu mới phải chứa ít nhất một số')
    .regex(/[\W_]/, 'Mật khẩu mới phải chứa ít nhất một ký tự đặc biệt'),
})

export type LoginType = z.infer<typeof loginBody>

export const LoginRes = z.object({
  code: z.number(),
  message: z.string(),
  data: z.object({
    token: z.string(),
    user: z.object({
      id: z.number(),
      full_name: z.string(),
      phone: z.string(),
      email: z.string().nullable(),
      avatar: z.string().nullable(),
      role: z.string(),
    }),
  }),
})

export type LoginResType = z.infer<typeof LoginRes>

export const UserMeRes = z.object({
  code: z.number(),
  message: z.string(),
  data: z.object({
    id: z.number(),
    full_name: z.string(),
    phone: z.string(),
    email: z.string().nullable(),
    avatar: z.string().nullable(),
    role: z.string(),
    verified_driving_license: z.boolean(),
    verified_identity_card: z.boolean(),
    verified_phone: z.boolean(),
    verified_email: z.boolean(),
    verified_info: z.array(
      z.object({
        id: z.number(),
        type: z.string(),
        identity_card_number: z.string(),
        identity_card_name: z.string(),
        identity_card_dated: z.string(),
        identity_card_dob: z.string(),
        identity_card_by: z.string(),
        identity_card_expiration: z.string(),
        identity_card_gender: z.string(),
        identity_card_address: z.string(),
        identity_card_before: z.string(),
        identity_card_after: z.string(),
        driving_license_image: z.string(),
        driving_license_number: z.string(),
        driving_license_name: z.string(),
        driving_license_dated: z.string(),
        driving_license_expiration: z.string(),
      })
    ),
  }),
})

export type UserMeResType = z.infer<typeof UserMeRes>
