'use client'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { signIn } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { useForm } from 'react-hook-form'
import google from '@/assets/images/google.svg'
import logo from '@/assets/images/logo.svg'
import InputField from '@/components/common/InputField'
import { getSchemaLogin, LoginType } from '@/schema/auth.schema'
import { encryptTextAES } from '@/utils/CryptoJSLib'
import { toast } from 'sonner'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Button } from '@/components/ui/button'

const BASE_URL = process.env.NEXT_PUBLIC_CLIENT_URL

function LoginContainer() {
  const t = useTranslations('auth.login')
  const form = useForm<LoginType>({
    resolver: zodResolver(getSchemaLogin(t)),
    defaultValues: {
      phone: '',
      password: '',
    },
  })

  async function onSubmit(values: LoginType) {
    try {
      await signIn('login', {
        ...values,
        password: encryptTextAES(values.password),
        source: "CLIENT",
        redirect: false,
        callbackUrl: BASE_URL,
      })
        .then((res) => {
          if (res?.ok) {
            console.log('Login success', res)
            // router.back();
          } else {
            toast.error('Mật khẩu hoặc số điện thoại không đúng!')
          }
        })
        .catch(() => {
          toast.error('Mật khẩu hoặc số điện thoại không đúng!')
        })
    } catch (error) {
      toast.error('Mật khẩu hoặc số điện thoại không đúng!')
      console.error(error)
    }
  }

  function LoginGoogle() {
    signIn('google', { callbackUrl: BASE_URL })
      .then((r) => console.log(r))
      .catch((e) => console.log(e))
  }

  return (
    <div className="h-full self-stretch flex flex-col items-center justify-center py-0 md:px-5 box-border max-w-full text-center text-11xl text-gray-900 font-text-sm-semibold">
      <div className="flex flex-col items-center justify-start w-full max-w-sm px-4">
        <div className="self-stretch flex flex-col items-center justify-start gap-[24px] mb-8">
          <Image
            alt=""
            className="w-12 h-12 relative"
            height={48}
            loading="lazy"
            src={logo}
            width={48}
          />
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
            <h1 className="m-0 self-stretch relative leading-[38px] font-semibold text-3xl">
              {t('dang_nhap')}
            </h1>
          </div>
        </div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-8 w-full"
          >
            <div className="m-0 self-stretch rounded-xl flex flex-col items-center justify-start w-full">
              <div className="self-stretch flex flex-col items-start justify-start gap-y-5 w-full">
                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <InputField
                          contentPlaceholder={t('nhap_sdt')}
                          label={t('sdt')}
                          field={field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <InputField
                          contentPlaceholder={t('mat_khau_placeholder')}
                          iconPassword
                          label={t('mat_khau')}
                          field={field}
                          type="password"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex justify-between w-full my-6">
                <div className="flex justify-start items-center">
                  <input
                    className="border-base-gray-300 rounded h-5 w-5"
                    type="checkbox"
                  />
                  <p className="text-sm text-base-gray-700 font-medium ml-1">
                    {t('ghi_nho_dang_nhap')}
                  </p>
                </div>
                <div className="cursor-pointer">
                  <p className="text-sm text-base-blue-600 font-semibold">
                    {t('quen_mat_khau')}
                  </p>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
                <Button
                  className="cursor-pointer py-2 px-5 bg-brand-solid self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg overflow-hidden flex flex-row items-center justify-center gap-[6px] border-[1px] border-solid border-brand-500-base"
                  type="submit"
                  disabled={
                    form.getValues('password') === '' ||
                    form.getValues('phone') === ''
                  }
                >
                  <div className="flex flex-row items-center justify-center py-0 px-0.5">
                    <div className="relative text-white leading-[24px] font-semibold font-text-sm-semibold text-base-white text-left inline-block min-w-[64px]">
                      {t('dang_nhap')}
                    </div>
                  </div>
                </Button>
                <button
                  className="cursor-pointer [border:none] p-0 bg-[transparent] self-stretch flex flex-col items-center justify-center gap-[12px]"
                  onClick={LoginGoogle}
                  type="button"
                >
                  <div className="self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg bg-base-white overflow-hidden flex flex-row items-center justify-center py-2 px-5 gap-[11px] whitespace-nowrap border-[1px] border-solid border-gray-300">
                    <Image
                      alt=""
                      className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
                      height={24}
                      src={google}
                      width={24}
                    />
                    <div className="relative text-base leading-[24px] font-semibold font-text-sm-semibold text-gray-700 text-left">
                      {t('dang_nhap_google')}
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </form>
        </Form>
        <div className="flex flex-row items-baseline justify-center py-0 gap-[4px] text-left text-sm text-gray-600 mt-8">
          <div className="relative leading-[20px]">
            {t('ban_chua_co_tai_khoan')}
          </div>
          <div className="overflow-hidden flex flex-row items-center justify-center gap-[6px] text-link">
            <div className="relative leading-[20px] font-semibold inline-block min-w-[73px]">
              <a href="/?isOpenModalSignUp=1">{t('dang_ky')}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginContainer
