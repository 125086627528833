'use client'
import { useMemo, useState, type CSSProperties } from 'react'
import { EyeSvg, EyeOffSvg } from '@/icons-svg/SvgIcons'
import clsx from "clsx";

type InputFieldProps = {
  field?: any
  label?: string
  contentPlaceholder?: string
  type?: string
  propMinWidth?: CSSProperties['minWidth']
  iconPassword?: boolean
  disabled?: boolean
  required?: boolean
}

export default function InputField({
  contentPlaceholder,
  iconPassword,
  label,
  propMinWidth,
  field,
  type,
  disabled,
  required,
}: InputFieldProps) {
  const labelStyle: CSSProperties = useMemo(
    () => ({
      minWidth: propMinWidth,
    }),
    [propMinWidth]
  )

  const [showPassword, setShowPassword] = useState(true)

  return (
    <div className="w-full self-stretch flex flex-col items-start justify-start gap-[6px] text-left text-sm text-gray-700 font-text-sm-semibold">
      <div className="w-full self-stretch flex flex-col items-start justify-start gap-[6px]">
        <label
          className={clsx("relative w-full leading-[20px] font-medium inline-block min-w-[45px] text-sm text-base-gray-700", required ? "after:content-['*'] after:text-red-600 after:px-1" : "")}
          style={labelStyle}
        >
          {label}
        </label>
        <div className="w-full self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg bg-base-white box-border flex flex-row items-center justify-start py-2 px-[13px] gap-[8px] border-[1px] border-solid border-gray-300">
          <input
            disabled={disabled ? disabled : false}
            {...field}
            className="w-full [border:none] [outline:none] bg-[transparent] h-6 flex-1 flex flex-row items-center justify-start font-text-sm-semibold text-base text-slategray min-w-[199px]"
            placeholder={contentPlaceholder}
            type={type && showPassword ? type : 'text'}
          />
          {iconPassword ? (
            <div
              className="cursor-pointer"
              onClick={() => {
                setShowPassword(!showPassword)
              }}
              onKeyDown={() => {
                setShowPassword(!showPassword)
              }}
              role="button"
              tabIndex={0}
            >
              {!showPassword ? <EyeSvg /> :  <EyeOffSvg />}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}
