import CryptoJS from 'crypto-js'

const key: string =
  process.env.REACT_APP_AES || 'KujEOYTVLtdfNopHmVnuTjEiZyqAmZpV'

export function encryptTextAES(data: any) {
  const ciphertext = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
  })

  return ciphertext.toString()
}

export function decryptTextAES(data: any) {
  const data2 = CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8)

  return CryptoJS.AES.decrypt(data2, CryptoJS.enc.Utf8.parse(key)).toString()
}
